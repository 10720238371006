<template>
    <div class="d-flex all-after-header-box">
        <!-- Левое меню модуля -->
        <div 
            :class="{'drawer-left-wrap' : true, 'dlw-large' : (!drawerLeftMenuMini), 'drawer-menu-hover': drawerLeftMenuHover }"
            @mouseenter="LeftMenuMouseenter()"
            @mouseleave="LeftMenuMouseleave()"
        >
            <v-navigation-drawer
                absolute
                permanent
                class="drawer-menu"
                v-model="drawerLeftMenu"
            >

                <template v-if="allowSaylauEdit">
                    <v-divider class="mr-2 ml-2"></v-divider>

                    <v-list
                        nav
                        dense
                        class="dm-actions-list"
                    >
                        <v-list-item-group
                            color="#009c95"
                        >
                            <v-list-item
                                link
                                class="v-item--active v-list-item--active drawer-menu-list-bigbtn"
                                @click="onCreateClicked"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-plus-box</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ $t("Добавить") }}</v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </template>

                <v-divider class="mr-2 ml-2"></v-divider>

                <v-list
                    nav
                    dense
                    shaped
                >
                    <v-list-item-group>
                        <v-list-item
                            link
                            :to="{ name: 'Voters' }"
                        >
                            <v-list-item-icon>
                            <v-icon>far fa-users</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("Избиратели") }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            link
                            :to="{ name: 'TempVoters' }"
                        >
                            <v-list-item-icon>
                            <v-icon>far fa-user-clock</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("Избиратели_по_заявлению") }}</v-list-item-title>
                        </v-list-item>

                    </v-list-item-group>
                </v-list>

                <v-divider class="mr-2 ml-2"></v-divider>

                <v-list
                    nav
                    dense
                    shaped
                >
                    <v-list-item-group>

                        <v-list-item
                            link
                            :to="{ name: 'ElectionParts' }"
                        >
                            <v-list-item-icon>
                            <v-icon>far fa-person-booth</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("Избирательные_участки") }}</v-list-item-title>
                        </v-list-item>
                        
                        <v-list-item
                            link
                            :to="{ name: 'Addresses' }"
                        >
                            <v-list-item-icon>
                            <v-icon>far fa-street-view</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("Адреса") }}</v-list-item-title>
                        </v-list-item>
                                         
                    </v-list-item-group>
                </v-list>

                <v-divider class="mr-2 ml-2"></v-divider>

                <v-list
                    nav
                    dense
                    shaped
                >
                    <v-list-group 
                        v-model="group1.active" 
                        :accordion="true"
                        @input="closeOtherGroups('group1')"
                    >
                        
                        <template v-slot:prependIcon>
                            <v-icon>far fa-search</v-icon>
                        </template>
                        <template v-slot:activator>
                            <v-list-item-title>{{ $t("Поиск") }}</v-list-item-title>
                        </template>
                        <template v-slot:appendIcon>
                            <v-icon>fas fa-chevron-down</v-icon>
                        </template>

                        <v-list-item
                            link
                            :to="{ name: 'LocalSearch' }"
                        >
                            <v-list-item-icon><v-icon>far fa-circle</v-icon></v-list-item-icon>
                            <v-list-item-title>{{ $t("По_локальным_данным") }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            link
                            :to="{ name: 'GbdflSearch' }"
                        >
                            <v-list-item-icon><v-icon>far fa-circle</v-icon></v-list-item-icon>
                            <v-list-item-title>{{ $t("По_данным_ГБД_ФЛ") }}</v-list-item-title>
                        </v-list-item>

                    </v-list-group>
                </v-list>

                <v-divider class="mr-2 ml-2"></v-divider>

                <v-list
                    nav
                    dense
                    shaped
                >
                    <v-list-group 
                        v-model="group2.active" 
                        :accordion="true"
                        @input="closeOtherGroups('group2')"
                    >
                        
                        <template v-slot:prependIcon>
                            <v-icon>far fa-file-chart-line</v-icon>
                        </template>
                        <template v-slot:activator>
                            <v-list-item-title>{{ $t("Аналитика") }}</v-list-item-title>
                        </template>
                        <template v-slot:appendIcon>
                            <v-icon>fas fa-chevron-down</v-icon>
                        </template>

                        <v-list-item
                            link
                            :to="{ name: 'VotersByRegion' }"
                        >
                            <v-list-item-icon><v-icon>far fa-circle</v-icon></v-list-item-icon>
                            <v-list-item-title>{{ $t("Свод_по_тер_единицам") }}</v-list-item-title>
                        </v-list-item>

                    </v-list-group>

                    <v-list-item-group>
                        <v-list-item
                            link
                            :to="{ name: 'Reports' }"
                        >
                            <v-list-item-icon>
                            <v-icon>far fa-file-spreadsheet</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t('Отчеты') }}</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>

                </v-list>

                <v-divider class="mr-2 ml-2"></v-divider>

                <v-list
                    nav
                    dense
                    shaped
                >
                    <v-list-group
                        v-model="group3.active" 
                        :accordion="true"
                        @input="closeOtherGroups('group3')"
                    >

                        <template v-slot:prependIcon>
                            <v-icon>far fa-th-list</v-icon>
                        </template>
                        <template v-slot:activator>
                            <v-list-item-title>{{ $t("Список_на_актуализацию") }}</v-list-item-title>
                        </template>
                        <template v-slot:appendIcon>
                            <v-icon>fas fa-chevron-down</v-icon>
                        </template>
                        <v-list-item
                            link
                            :to="{ name: 'VotersOnDistribution' }"
                        >
                            <v-list-item-icon><v-icon>far fa-circle</v-icon></v-list-item-icon>
                            <v-list-item-title>{{ $t("Список_лиц_без_адресов") }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            link
                            :to="{ name: 'VotersOutDistribution' }"
                        >
                            <v-list-item-icon><v-icon>far fa-circle</v-icon></v-list-item-icon>
                            <v-list-item-title>{{ $t("Список_лиц_без_участков") }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item
                            link
                            :to="{ name: 'VotersOnActualization' }"
                        >
                            <v-list-item-icon><v-icon>far fa-circle</v-icon></v-list-item-icon>
                            <v-list-item-title>{{ $t("Список_лиц_на_актуализацию") }}</v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </v-list>

            </v-navigation-drawer>
        </div>

        <div class="content-box">

            <router-view :key="$route.name"/>
            
        </div>

    </div>
</template>

<script>
import { mapGetters} from 'vuex';
export default {
    name: "Saylau",
    components: {
    },
    computed: {
        ...mapGetters(
        {
            drawerLeftMenuMini: 'isDrawerLeftMenuMinimized'
        }),
        allowSaylauEdit(){
            return this.$store.getters['global/auth/getUserPermissions'].includes("SaylauEdit");
        }
    },
    data: () => ({
        drawerLeftMenu: true,
        drawerLeftMenuHover: false,
        group1: {
            active: false
        },
        group2: {
            active: false
        },
        group3: {
            active: false
        }
    }),    
    methods: {
        LeftMenuMouseenter() {
            if (this.drawerLeftMenuMini)
                this.drawerLeftMenuHover = true;
        },
        LeftMenuMouseleave() {
            this.drawerLeftMenuHover = false;  
        },
        onCreateClicked() {
            this.$router.push({ name: 'ElectionPart', params: { id: 'new' } }).catch(()=>{});
        },
        closeOtherGroups(groupName) {
            if (groupName === 'group1') {
                this.group2.active = false;
                this.group3.active = false;
            } else if (groupName === 'group2') {
                this.group1.active = false;
                this.group3.active = false;
            }else if (groupName === 'group3') {
                this.group1.active = false;
                this.group2.active = false;
            }
        }
    }
}
</script>